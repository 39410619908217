import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import VueGtag from "vue-gtag";
import './assets/tailwind.css';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: 'G-3KLWY74ZLN' },
});

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
