








































import { Component, Prop, Vue } from 'vue-property-decorator';
import DogCard from '@/components/DogCard.vue';
import ShareButtons from '@/components/ShareButtons.vue';

@Component({
  components: {
    DogCard,
    ShareButtons,
  },
})
export default class GameOver extends Vue {
  @Prop() private dogs!: Array<any>;
  @Prop() private score!: number;
  @Prop() private dogsLength!: number;

  get highScore() {
    const highScore = localStorage.getItem('gb_sg_high_score') || '0';
    if (this.score > +highScore) {
      localStorage.setItem('gb_sg_high_score', this.score.toString());
    }
    return localStorage.getItem('gb_sg_high_score');
  }

  reload(): void {
    this.$gtag.event('play_again');
    window.location.reload();
  }

  coffeeClick(): void {
    this.$gtag.event('buy_coffee');
  }
}
