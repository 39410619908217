

































import {Component, Vue} from 'vue-property-decorator';
import axios from 'axios';
import DogCard from '@/components/DogCard.vue';
import GameScreen from '@/components/GameScreen.vue';

@Component({
  components: {
    DogCard,
    GameScreen,
  },
})
export default class Home extends Vue {
  play = false;
  dogs = [] as any;

  async mounted() {
    const getTokenParams = `grant_type=client_credentials&client_id=${process.env.VUE_APP_PETFINDER_KEY}&client_secret=${process.env.VUE_APP_PETFINDER_SECRET}`;
    await axios
      .post('https://api.petfinder.com/v2/oauth2/token', getTokenParams)
      .then((response) => {
        const getRandomDogsParams = `type=dog&limit=100&sort=random&gender=male,female`;
        axios
          .get(`https://api.petfinder.com/v2/animals?${getRandomDogsParams}`, {
            headers: {
              Authorization: `Bearer ${response.data.access_token}`,
            },
          })
          .then((res) => {
            this.dogs = this.filterDogs(res.data.animals);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  filterDogs(dogs: any) {
    return dogs
      .filter((dog: any) => {
        const nameLength = dog.name?.split(' ').length;
        const numName = /\d/.test(dog.name);
        return dog.photos?.length && nameLength && nameLength < 3 && !numName;
      })
      .slice(0, 20);
  }

  get dogsLength() {
    return this.dogs.length;
  }
}
