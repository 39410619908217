












import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class DogCard extends Vue {
  @Prop() private gender!: string;
  @Prop() private image!: string;
  @Prop() private name!: string;
}
