



























































import { Component, Prop, Vue } from 'vue-property-decorator';
import DogCard from '@/components/DogCard.vue';
import GameOver from '@/components/GameOver.vue';

@Component({
  components: {
    DogCard,
    GameOver,
  },
})
export default class GameScreen extends Vue {
  @Prop() private dogs!: Array<any>;

  isGenderRevealed = false;
  roundNumber = 1;
  score = 0;
  gameOver = false;
  dogsLength: number = this.dogs.length;

  get headerText() {
    return this.currentDogGender == 'Male' ? 'Good Boy!' : 'Sweet Girl!';
  }

  get currentDog() {
    return this.dogs[this.roundNumber - 1];
  }

  get currentDogGender() {
    return this.currentDog.gender;
  }

  revealGender(): void {
    this.isGenderRevealed = true;
  }

  handleGuess(guess: string): void {
    this.revealGender();
    if (guess == this.currentDogGender) {
      this.score++;
    }
  }

  next(): void {
    this.isGenderRevealed = false;
    this.roundNumber++;
    if (this.roundNumber > this.dogsLength) {
      this.gameOver = true;
      this.$gtag.event('score', { value: this.score });
    }
  }
}
